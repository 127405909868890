export default [
  {
    path: '/marketManagement',
    name: 'marketManagement',
    component: () => import(/* webpackChunkName: "marketManagement" */ './index'),
    meta: {
      type: 'marketManagement'
    },
    redirect: '/marketManagement/materialList',
    children: [
      {
        path: 'materialList',
        name: 'materialList',
        component: () => import(/* webpackChunkName: "marketManagement" */ './materialList')
      },
      {
        path: 'programList',
        name: 'programList',
        component: () => import(/* webpackChunkName: "marketManagement" */ './programList')
      },
      {
        path: 'marketProcessProgress',
        component: () =>
          import(/* webpackChunkName: "marketManagement" */ './marketProcessProgress'),
        redirect: '/marketProcessProgress/index',
        children: [
          {
            path: 'index',
            name: 'marketProcessProgress',
            component: () =>
              import(
                /* webpackChunkName: "marketManagement" */ './marketProcessProgress/views/operateList.vue'
              )
          },
          {
            path: 'appDetail/:id',
            name: 'marketProcessProgressDetail',
            component: () =>
              import(
                /* webpackChunkName: "marketManagement" */ './marketProcessProgress/views/appDetail.vue'
              )
          }
        ]
      }
    ]
  }
]
